import React from 'react';
import { Helmet } from 'react-helmet';
import { PostCard } from "./post";

const PostOgImage = (data) => {
  const background = data.pageContext.frontmatter.background;
  data.pageContext.frontmatter.background = "transparent";
  return <>
    <Helmet>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>

      <link href="https://fonts.googleapis.com/css?family=PT+Serif:400,700" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css?family=PT+Serif:300,400,600,700,800" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono" rel="stylesheet"/>

      <style type="text/css">{`
        body {
          margin: 0;
          background: ${background};
          font-family: 'Open Sans', sans-serif;
        }
      `}</style>
    </Helmet>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
    }}>
      <div style={{
        maxWidth: data.pageContext.frontmatter.ogContentWidth || "600px"
      }}>
        <PostCard post={data.pageContext} noShadow smallTitle />
      </div>
    </div>
  </>;
};

export default PostOgImage;
